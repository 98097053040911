import { computed, ref, useContext, useStore } from '@nuxtjs/composition-api';

const FORM_STATIC_PARAMS = {
  site: 'Lorgar EU',
};

const useForm = (fields) => {
  const {
    i18n,
    $api: { formsService },
  } = useContext();
  const store = useStore();
  const defaultLocale = store?.getters['config/getDefaultLanguage']?.code;

  const currentLocaleISO = computed(
    () =>
      i18n.locales.find(
        (locale) => locale?.code === (i18n.locale || defaultLocale)
      ).iso
  );

  const formFields = ref({
    ...fields,
    ...FORM_STATIC_PARAMS,
    lang: currentLocaleISO.value,
  });

  const submit = async (formData) => {
    await formsService.sendFormData(formData);
  };

  const resetForm = () => {
    formFields.value = {
      ...fields,
      ...FORM_STATIC_PARAMS,
      lang: currentLocaleISO.value,
    };
  };

  return {
    formFields,
    submit,
    resetForm,
  };
};

export default useForm;
